import uuid from "uuid";

/**
 * Class for static utility functions
 */
export default class Utils {

  /**
   * Generates random uuid version 4
   */
  static uuid4(): string {
    return uuid.v4();
  }

  /**
   * Sets cookie
   * 
   * @param name cookie name
   * @param value cookie value
   * @param days expirary time in days
   */
  static setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  /**
   * Gets cookie
   * 
   * @param name cookie name
   */
  static getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  /**
   * Removes cookie
   * 
   * @param name cookie name
   */
  static eraseCookie(name: string) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}