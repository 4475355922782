import { connect, MqttClient } from "mqtt";
import QualeticsMessage, { Actor, MetadataGeolocationPosition, QualeticsMessageData } from "./qualetics-message";
import Utils from "./utils";

export interface Options {
  host?: string,
  port?: number,
  defaultActor?: Actor,
  stickySessionId?: boolean,
  storeDefaultActorFromApiCall?: boolean,
  trackUserGeoLocation?: boolean,
  trackPageVisibilityChanges?: boolean,
  subscribeToTopics?: string[],
  appVersion?: string,
  disableErrorCapturing?: boolean
  captureClicks?: boolean,
  captureTimings?: boolean
}

let instance: QualeticsService | null = null

/**
 * Class that handles communication with Qualetics API.
 * 
 */
class QualeticsService {

  private host: string;
  private port: number;
  private userName: string;
  private applicationId: string;
  private applicationKey: string;
  private sessionId: string;
  private client?: MqttClient;
  private trackPageViews: boolean;
  private connected: boolean;
  private initialConnectionMade: boolean;
  private retryCount: number;
  private defaultActor: Actor;
  private onConnectCallbacks: { (): void; }[];
  private onMessageCallbacks: { (topic: string, message: QualeticsMessageData): void; }[];
  private subsribeToTopics: string[];
  private pageviewTracked: boolean;
  private storeDefaultActorFromApiCall: boolean;
  private forcedDefaultActor: boolean;
  private trackUserGeoLocation: boolean;
  private position?: MetadataGeolocationPosition;
  private connectionSuccessTimer: any;
  private appVersion?: string;
  private pendingMessages: QualeticsMessage[];
  private captureTimings: boolean;

  /**
   * Constructor
   * 
   * @param applicationId application id
   * @param applicationKey application secret
   * @param sessionPrefix session prefix
   * @param trackPageViews automatically track page views (optional, defaults to true on browser, false on node)
   * @param options options object (optional)
   */
  constructor(applicationId: string, applicationKey: string, sessionPrefix: string, trackPageViews?: boolean, options?: Options) {
    if (!applicationId || !applicationKey || !sessionPrefix) {
      throw new Error("Error:QAPI-003:applicationId, applicationKey and sessionPrefix/appdomain are required parameters");
    }
    if (instance) {
      instance.end();
    }

    const nodeEnv = typeof window == "undefined";
    this.pendingMessages = [];
    this.host = options ? options.host || "ws://api.qualetics.com" : "ws://api.qualetics.com";
    this.port = options ? options.port || 8083 : 8083;
    this.initialConnectionMade = false;
    this.retryCount = 0;
    const storedDefaultActorString = nodeEnv ? undefined : Utils.getCookie("defaultActor");
    const storedDefaultActor = storedDefaultActorString ? JSON.parse(storedDefaultActorString) : { type: "System" };
    this.defaultActor = options ? options.defaultActor || storedDefaultActor : { type: "System" };
    this.forcedDefaultActor = options && options.defaultActor ? true : false;
    this.userName = nodeEnv ? applicationId : `${applicationId}__${window.location.origin.replace("http://", "").replace("https://", "")}`;
    this.applicationId = this.userName;
    this.applicationKey = applicationKey;
    this.trackPageViews =  nodeEnv ? false : trackPageViews == undefined ? true : trackPageViews;
    this.captureTimings =  nodeEnv ? false : options && options.captureTimings ? options.captureTimings : false;
    this.pageviewTracked = false;
    this.onConnectCallbacks = [];
    this.onMessageCallbacks = [];
    this.subsribeToTopics = options ? options.subscribeToTopics || [] : [];
    this.storeDefaultActorFromApiCall = nodeEnv ? false : options && options.storeDefaultActorFromApiCall !== undefined ? options.storeDefaultActorFromApiCall : true;
    let sessionPrefixCleaned = sessionPrefix
      .replace("http://", "")
      .replace("https://", "");
    sessionPrefixCleaned = sessionPrefixCleaned.replace(/\//g, '');
    this.sessionId = `${sessionPrefixCleaned}:${Utils.uuid4()}`;
    this.appVersion = options ? options.appVersion : undefined;
    const useStickySessionIds = options && options.stickySessionId !== undefined ? options.stickySessionId : true;
    if (useStickySessionIds && !nodeEnv) {
      const storedSessionId = Utils.getCookie("sessionId");
      let storedSessionIndex = Number(Utils.getCookie("sessionIndex"));
      if (storedSessionId && storedSessionId.startsWith(sessionPrefixCleaned)) {
        this.sessionId = `${storedSessionId}_._${storedSessionIndex}`;
        storedSessionIndex++;
        Utils.setCookie("sessionIndex", storedSessionIndex.toString(), 1);
      } else {
        Utils.setCookie("sessionId", this.sessionId, 1);
        Utils.setCookie("sessionIndex", "0", 1);
      }
    }
    const trackPageVisibilityChanges = options && options.trackPageVisibilityChanges !== undefined ? options.trackPageVisibilityChanges : true;
    if (trackPageVisibilityChanges && !nodeEnv) {
      document.addEventListener("visibilitychange", this.handleDocumentVisibilityChange.bind(this), false);
    }
    const captureErrors = nodeEnv ? false : options && options.disableErrorCapturing == true ? false : true;
    if (captureErrors) {
      window.onerror = (message, source, linenumber, colnumber, error) => {
        this.createMessage()
          .setActor(this.defaultActor)
          .setAction({
            type: "Exception",
            name: error ? error.name : "Error"
          })
          .setContext({
            type: "Page",
            name: source || window.location.href,
            attributes: {
              errormessage: message,
              stacktrace: error ? error.stack : "",
              linenumber: linenumber,
              colnumber: colnumber,
              ...this.captureQueryStringParameters()
            }
          }).send()
      }
    }
    const captureClicks = !nodeEnv && options && options.captureClicks ? options.captureClicks : false;
    if (captureClicks) {
      window.onclick = (e) => {
        let target: any = e.target || {};
        let name = target.getAttribute && target.getAttribute("title") ? target.getAttribute("title") : target.tagName || "Unknown";
        this.createMessage()
          .setActor(this.defaultActor)
          .setAction({
            type: "Click"
          })
          .setContext({
            type: "Page",
            name: window.location.href,
            attributes: this.captureQueryStringParameters()
          })
          .setObject({
            type: "Element",
            name: name,
            attributes: {
              id: target.id || "",
              description: target.getAttribute && target.getAttribute("description") ? target.getAttribute("description") : "",
              value: target.value || ""
            }
          }).send()
      }
    }
    this.trackUserGeoLocation = !nodeEnv && options && options.trackUserGeoLocation ? options.trackUserGeoLocation : false;
    this.connected = false;
    instance = this;
  }

  captureActiveElement(): {[key: string] : string} {
    const activeElement = document.activeElement;
    if (!activeElement) {
      return {};
    } 
    return {
      activeElementId: activeElement.id,
      activeElementTitle: activeElement.getAttribute("data-title") || activeElement.tagName,
      activeElementDescription: activeElement.getAttribute("data-description") || ""
    }
  }

  captureQueryStringParameters(): {[key: string] : string} {
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return Object.fromEntries(urlSearchParams.entries());
    }

    return {};
  }

  handleDocumentVisibilityChange() {
    const pageVisibilityMessage = this.createMessage()
      .setActor(this.defaultActor)
      .collectTiming(this.captureTimings)
      .usePageObject()
      .setContext({
        type: "Page",
        name: window.location.href,
        attributes: { ...this.captureActiveElement(), ...this.captureQueryStringParameters() }
      }); 

    if (document.hidden) {
      pageVisibilityMessage.setAction({
        type: "PageExit"
      });
    } else {
      pageVisibilityMessage.setAction({
        type: "PageReturn"
      });
    }

    pageVisibilityMessage.send();
  }

  getUserPosition(): Promise<MetadataGeolocationPosition> {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(position);
        }, (err) => {
          reject(err);
        });
      });
    } else {
      return Promise.reject("Geolocation is not supported by this environment");
    }
  }

  /**
   * Initializes the service and possibly waits for required values to be initalized before connecting
   */
  init() {
    if (this.trackUserGeoLocation) {
      this.getUserPosition()
        .then((position) => {
          this.position = position;
        })
        .catch((err) => {
          console.error("Error:QAPI-002:Error getting user geo location", err);
        })
        .then(() => {
          this.doInit();
        })
    } else {
      this.doInit();
    }
  }

  /**
   * Performs the actual connection to the server
   */
  doInit() {
    if (this.connected && this.client && this.client.connected) {
      return;
    }

    if (this.client) {
      this.client.end();
    }

    this.client = connect(`${this.host}:${this.port}/mqtt`, {
      username: this.userName,
      password: this.applicationKey,
      clientId: this.sessionId,
      reconnectPeriod: 0
    });

    this.client.on("close", () => {
      if (this.connectionSuccessTimer) {
        clearTimeout(this.connectionSuccessTimer);
      }
      this.retryCount++;
      if (!this.initialConnectionMade && this.retryCount > 5) {
        console.error("Error:QAPI-001:Connection was not created after 5 attempts, giving up")
        return;
      }
      setTimeout(() => {
        if (this.client) {
          this.client.reconnect();
        }
      }, 1000);
    });

    this.client.on('message', (topic, messageBuffer) => {
      try {
        const message: QualeticsMessageData = JSON.parse(messageBuffer.toString())
        this.onMessageCallbacks.forEach((callback) => {
          callback(topic, message);
        });
      } catch(err) {
        console.error("Non JSON message received", err);
      }
    });

    this.client.on("connect", () => {
      this.connected = true;
      this.connectionSuccessTimer = setTimeout(() => this.initialConnectionMade = true, 5000);
      this.onConnectCallbacks.forEach((callback) => {
        callback();
      });

      this.subsribeToTopics.forEach((topic: string) => {
        if (this.client) {
          this.client.subscribe(topic, (err) => {
            if (err) {
              console.error(`Error subscribing to topic: ${topic} `, err);
            }
          });
        }
      });

      if (this.trackPageViews && !this.pageviewTracked) {
        this.pageviewTracked = true;
        const pageViewMessage = this.createMessage();
        pageViewMessage.setAction({
          type: "PageView"
        })
        .collectTiming(this.captureTimings)
        .setActor(this.defaultActor)
        .usePageObject()
        .setContext({
          type: "Page",
          name: window.location.href,
          attributes: { ...this.captureActiveElement(), ...this.captureQueryStringParameters()}
        }).send();
      }

      this.pendingMessages.forEach((message) => this.sendMessage(message));
      this.pendingMessages = [];
    });
  }

  end() {
    if (this.client) {
      this.client.end();
      this.client = undefined;
    }
    if (this.connectionSuccessTimer) {
      clearTimeout(this.connectionSuccessTimer);
      this.connectionSuccessTimer = null;
    }
  }

  /**
   * Creates topic for the message
   */
  buildTopic(): string {
    return  `/v1/data/${this.applicationId}/${this.sessionId}/event/`;
  }

  /**
   * Add connection event listener
   * 
   * @param callback function to run once connected to the server
   */
  onConnect(callback: { (): void; }) {
    this.onConnectCallbacks.push(callback);
  }

  /**
   * Add message event listener
   * 
   * @param callback function to run when message is received
   */
  onMessage(callback: { (topic: string, message: QualeticsMessageData): void; }) {
    this.onMessageCallbacks.push(callback);
  }

  /**
   * Creates new message from raw data and sends it
   * 
   * @param data data to construct the message from 
   */
  send(data: QualeticsMessageData) {
    const message: QualeticsMessage = new QualeticsMessage(data);
    this.sendMessage(message);
  }

  /**
   * Sends already initialized message
   * 
   * @param message message object 
   */
  sendMessage(message: QualeticsMessage) {
    if (!this.connected) {
      this.pendingMessages.push(message);
      return;
    }
    const actor = message.getActor();
    if (this.storeDefaultActorFromApiCall && actor && !this.forcedDefaultActor) {
      this.defaultActor = actor;
      Utils.setCookie("defaultActor", JSON.stringify(actor), 1);
    }
    if (!actor) {
      message.setActor(this.defaultActor);
    }

    if (this.appVersion) {
      message.setAppVersion(this.appVersion);
    }

    if (this.client) {
      this.client.publish(this.buildTopic(), message.getMessage(this.position));
    }
  }

  /**
   * Returns app version specified by the user
   * 
   * @returns specified app version
   */
  getAppVersion() {
    return this.appVersion;
  }

  /**
   * Creates new instance of message and attaches reference to this service
   */
  createMessage() {
    return new QualeticsMessage(undefined, this);
  }

}

export default QualeticsService;
