import Ajv, { ValidateFunction, ErrorObject } from "ajv"
import { QualeticsMessageData } from "./qualetics-message";
import * as messageSchema from "./schemas/qualeticsMessageData.json";

/**
 * Class that uses json schema to validate messages
 */
class QualeticsMessageValidator {

  private ajv: Ajv;
  private validate: ValidateFunction<QualeticsMessageData>;

  constructor() {
    this.ajv = new Ajv();
    this.validate = this.ajv.compile<QualeticsMessageData>(messageSchema);
  }

  /**
   * Validates message using JSON schame
   * 
   * @param message Message to validate
   * @returns list of errors or null if message is valid
   */
  validateMessage(message: QualeticsMessageData): ErrorObject[] | undefined | null {
    const valid = this.validate(message);
    if (!valid) {
      return this.validate.errors
    }

    return null;
  }

}

const instance = new QualeticsMessageValidator();
export default instance;