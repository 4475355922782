import QualeticsService from "./qualetics-service";
import validator from "./qualetics-message-validator";

export interface Actor {
  type: string,
  id?: string,
  attributes?: object
}

export interface Action {
  type: string,
  name?: string
  attributes?: object
}

export interface Context {
  type: string,
  name: string,
  attributes?: object
}

export interface QualeticsObject {
  type: string,
  name: string,
  attributes?: object
}

export interface Metadata {
  timestamp: string
  userAgent?: string,
  geoLocation?: MetadataGeolocation
  appVersion?: string
  metaTags?: { [key: string]: string }
  performance?: Performance
}

export interface Performance {
  startTime?: number
  requestStart?: number
  responseStart?: number
  responseEnd?: number
  loadEventStart?: number
  loadEventEnd?: number
}

export interface MetadataGeolocation {
  accuracy: number | null
  altitude: number | null
  altitudeAccuracy: number | null
  heading: number | null
  latitude: number | null
  longitude: number | null
  speed: number | null
}

export interface MetadataGeolocationPosition {
  coords: MetadataGeolocation
}

export interface QualeticsMessageData {
  actor?: Actor,
  action?: Action,
  context?: Context,
  object?: QualeticsObject,
  metadata?: Metadata,
}

/**
 * Wrapper class for the message that introduces helper methods
 * to make building messages easier.
 */
class QualeticsMessage {

  private service!: QualeticsService;
  private data!: QualeticsMessageData;
  private appVersion?: string;
  private useTimings?: boolean;

  /**
   * Constructor 
   *
   * @param data Optional data that the message will be constructed from
   * @param service Optional reference to service that constructed the message
   */
  constructor(data?: QualeticsMessageData, service?: QualeticsService) {
    if (data) {
      this.fromObject(data);
    }

    if (service) {
      this.service = service;
    }
  }

  /**
   * Gets the actor for the message
   * 
   * @param actor Actor object
   */
  getActor(): Actor | undefined {
    if (!this.data) {
      this.data = {};
    }

    return this.data.actor;
  }

  /**
   * Sets the actor for the message
   * 
   * @param actor Actor object
   */
  setActor(actor: Actor): QualeticsMessage {
    if (!this.data) {
      this.data = {};
    }

    this.data.actor = actor;
    return this;
  }

  /**
   * Sets the action for the message
   * 
   * @param action Action object
   */
  setAction(action: Action): QualeticsMessage {
    if (!this.data) {
      this.data = {};
    }

    this.data.action = action;
    return this;
  }

  /**
   * Sets the context object for the message
   * 
   * @param context Context object 
   */
  setContext(context: Context): QualeticsMessage {
    if (!this.data) {
      this.data = {};
    }

    this.data.context = context;
    return this;
  }

  /**
   * Gets the object for the message
   * 
   * @returns Object of the message
   */
  getObject(): QualeticsObject | undefined {
    if (!this.data) {
      this.data = {};
    }

    return this.data.object;
  }

  /**
   * Sets the object for the message
   * 
   * @param object Message object to set
   * 
   * @returns Message instance
   */
  setObject(object: QualeticsObject): QualeticsMessage {
    if (!this.data) {
      this.data = {};
    }

    this.data.object = object;
    return this;
  }

  /**
   * Attaches page object to message with page meta tags
   * 
   * @returns this message instance
   */
  usePageObject(): QualeticsMessage {
    if (typeof window !== "undefined") {
      this.data.object = {
        type: "Page",
        name: window.location.href,
        attributes: this.collectMetaTags()
      }
    }
    return this;
  }

  /**
   * Sets appversion for the message
   * 
   * @param appVersion current applications version
   */
  setAppVersion(appVersion: string) {
    this.appVersion = appVersion;
  }

  /**
   * Sets message to collect timing from performance object
   */
  collectTiming(useTimings: boolean): QualeticsMessage {
    this.useTimings = useTimings;
    return this;
  }

  /**
   * Builds complete message from data
   * 
   * @param data Data representing the message
   */
  fromObject(data: QualeticsMessageData): QualeticsMessage {
    this.data = data;
    return this;
  }

  /**
   * Collects automatically added metadata to the request
   */
  collectMetadata(position?: MetadataGeolocationPosition) {
    const metadata: Metadata = {
      timestamp: new Date().toUTCString()
    };
    if (typeof window !== "undefined" && navigator && navigator.userAgent) {
      metadata.userAgent = navigator.userAgent;
    }
    if (position) {
      metadata.geoLocation = {
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        speed: position.coords.speed
      };
    }
    if (this.appVersion) {
      metadata.appVersion = this.appVersion;
    }
    return metadata;
  }

  /**
   * Collects entries from browser performance api
   * 
   * @returns Performance object
   */
  getPerformanceEntries(): Performance {
    let navigationEntries = window && window.performance ? window.performance.getEntriesByType("navigation") || [] : []
    let entries = navigationEntries.length > 0 ? navigationEntries[0].toJSON() : {};
    return {
      startTime: entries.startTime,
      loadEventEnd: entries.loadEventEnd,
      loadEventStart: entries.loadEventStart,
      requestStart: entries.requestStart,
      responseEnd: entries.responseEnd,
      responseStart: entries.responseStart
    }
  }

  /**
   * Collects meta tags from current page
   * 
   * @returns Object containing meta tag properties and contents
   */
  collectMetaTags(): {[key: string]: string} {
    const metas = document.getElementsByTagName('meta');
    const result: {[key: string]: string} = {};
    for (let i = 0; i < metas.length; i++) {
      let property = metas[i].getAttribute('property')
      let content = metas[i].getAttribute('content')
      if (property && content) {
        result[property] = content;
      }
    }
  
    return result;
  }

  /**
   * Returns the message data in stringified json format
   */
  getMessage(position?: MetadataGeolocationPosition): string {
    if (!this.data.action) {
      throw new Error("Actions is required");
    }

    if(!this.data.actor) {
      throw new Error("Actor is required");
    }

    if(!this.data.context) {
      throw new Error("Context is required");
    }
    this.data.metadata = this.collectMetadata(position);
    
    if (this.useTimings && typeof window !== "undefined") {
      this.data.context.attributes = {...this.data.context.attributes, ...this.getPerformanceEntries()}
    }
    
    const errors = validator.validateMessage(this.data);
    if (errors) {
      console.error(errors);
      throw new Error(`Message validation failed: ${errors.map((e) => `${e.instancePath} ${e.message}`).join(", ")}`);
    }

    return JSON.stringify(this.data);
  }

  /**
   * Sends constructed message
   */
  send() {
    if (!this.service) {
      throw new Error("Attempted to send message without service");
    }

    this.service.sendMessage(this);
  }
}

export default QualeticsMessage;